import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AppCartService } from 'src/services/app-cart/app-cart.service';
import { AppCurrencyService } from 'src/services/app-currency/app-currency.service';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import { AppLanguageService } from 'src/services/app-language/app-language.service';
import { AppLogService } from 'src/services/app-log/app-log.service';
import { AppRecentProductsService } from 'src/services/app-recentproducts/app-recent-products.service';
import { AppWishListService } from 'src/services/app-wishlist/app-wish-list.service';
import { ConfigService } from 'src/services/config/config.service';
import { LoadingService } from 'src/services/loading/loading.service';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';

@Component({
  selector: 'app-demo-settings',
  templateUrl: './demo-settings.page.html',
  styleUrls: ['./demo-settings.page.scss'],
})


export class DemoSettingsPage implements OnInit {
  colors = [
    { value: '#0478ed', name: 'default' },
    //{ value: '#ffffff', name: 'white' },
    { value: '#EC3F34', name: 'green-theme' },
    { value: '#BF04A0', name: 'red-theme' },
    { value: '#FCAD8E', name: 'magnesium-theme' },
    { value: '#FF8EA6', name: 'salmon-theme' },
    { value: '#44B3FF', name: 'plum-theme' },
    { value: '#9546FE', name: 'blue-theme' },
    { value: '#A6633C', name: 'pink-theme' },
    { value: '#3CA68D', name: 'orange-theme' },
    { value: '#3C51A6', name: 'maroon-theme' },
    { value: '#726DFF', name: 'cayanne-theme' },
    { value: '#FF6D6D', name: 'sea-theme' },
    { value: '#B3182A', name: 'theme15' },
    { value: '#3980ff', name: 'theme16' },
    { value: '#483A6F', name: 'theme17' },
    { value: '#621529', name: 'theme18' },

    { value: '#fb8500', name: 'theme19' },
    { value: '#f72585', name: 'theme20' },
    { value: '#606c38', name: 'theme21' },
    { value: '#582f0e', name: 'theme22' },
    { value: '#ffc300', name: 'theme23' },
    { value: '#bfd200', name: 'theme24' },
    { value: '#77bfa3', name: 'theme25' },
    { value: '#2e294e', name: 'theme26' },
    { value: '#22333b', name: 'theme27' },
    { value: '#118ab2', name: 'theme28' },
    { value: '#e63946', name: 'theme29' },
    { value: '#f2542d', name: 'theme30' },
    { value: '#0466c8', name: 'theme31' },
    { value: '#c5d86d', name: 'theme32' },

    // { value: '#76d6ff', name: 'sky' },
    // { value: '#9437ff', name: 'grape' },
  ]
  //#000000, #EC3F34, #BF04A0, #FCAD8E, #FF8EA6, #44B3FF, #9546FE, #A6633C, #3CA68D, #, #, #
  translate;
  themeMode: any = 'dark';
  loaderLanguages = true;
  loaderCurrecny = true;

  banner = "1";
  constructor(
    public loading: LoadingService,
    public modalCont: ModalController,
    public config: ConfigService,
    public appCartService: AppCartService,
    public nav: NavController,
    public appHttpService: AppHttpService,
    public shared: SharedDataService,
    public appLanguageService: AppLanguageService,
    public appCurrencyService: AppCurrencyService,
    public appWishListService: AppWishListService,
    public appRecentProductsService: AppRecentProductsService,
    public appLogService: AppLogService) {

    this.getLanguages();
    this.getListOfCurrency();


    if (this.config.darkModeBool) {
      this.themeMode = 'dark'
    }
    else {
      this.themeMode = 'light'
    }

    //this.banner = this.config.bannerStyle;

    console.log(this.config.darkModeBool, this.themeMode);
  }
  modeChange() {
    if (this.themeMode == 'dark') {
      this.config.enableDarkMode(true);
    }
    else {
      this.config.enableDarkMode(false);
    }
  }

  setBannerStyle(banner) {
    // this.config.setBannerStyle(banner);
    // this.config.bannerStyle = banner
  }
  setCardStyle(card) {
    this.config.productCardStyleNumber = card;
    this.dismiss();
  }
  setCategoryStyle(s) {
    this.config.categoryPageNumber = s
    this.dismiss();
  }
  setHomeStyle(s) {
    this.config.homePageNumber = s
    this.dismiss();
  }
  //close modal
  dismiss() {
    this.modalCont.dismiss();
  }

  ngOnInit() {
  }
  changeAppTheme(value) {
    this.config.appThemeString = value;
  }
  //================================================================
  public languagesArray = [];
  selectedLanguage;
  getLanguages() {
    this.loaderLanguages = false
    this.languagesArray = this.appLanguageService.languagesArray;
    for (let data of this.languagesArray) {
      if (data.code == this.config.languageCodeString) {
        this.selectedLanguage = data;
      }
    }
  }
  updateLanguage(lang) {
    this.appLanguageService.updateLanguage(lang)
  }
  //===============================================================

  selectedCurrency: any;
  currencyListArray = [];

  getListOfCurrency() {
    this.currencyListArray = this.appCurrencyService.currencyList;
    this.loaderCurrecny = false
    this.currencyListArray.forEach(val => {
      if (this.config.currencyCodeString.toLocaleLowerCase() == val.name.toLocaleLowerCase()) {
        if (this.selectedCurrency == undefined) {
          console.log(this.selectedCurrency)
          this.selectedCurrency = val;
        }
      }
    });
    return this.appCurrencyService.currencyList
  }

  updateCurrency(value) {

    if (value != undefined && this.config.currencyCodeString.toLocaleLowerCase() != value.name.toLocaleLowerCase()) {
      this.loading.show()
      localStorage.currencyIdNumber = value.currency_id
      localStorage.currencyCodeString = value.name
      localStorage.currencySymbolString = value.symbol
      localStorage.currencyPositionString = value.symbol_position
      localStorage.currencyDecimalNumber = value.decimal_place

      //this.appCartService.emptyCart();
      //this.appRecentProductsService.emptyRecentViewed();
      setTimeout(() => {
        window.location.reload()
        this.loading.hide()
      }, 900)
    }
  }
}
