import { Injectable } from '@angular/core';
import { AppEventsService } from '../app-events/app-events.service';
import { AppHttpService } from '../app-http/app-http.service';
import { AppStorageService } from '../app-storage/app-storage.service';
import { AppToastService } from '../app-toast/app-toast.service';
import { AppUserService } from '../app-user/app-user.service';
import { ConfigService } from '../config/config.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class AppWishListService {
  public wishListArray = [];
  constructor(
    public loading: LoadingService,
    public appUserService: AppUserService,
    public appToastService: AppToastService,
    public appEventsService: AppEventsService,
    public config: ConfigService,
    public storage: AppStorageService,
    public appHttpService: AppHttpService,) {
    this.registerEvents()
  }
  registerEvents() {
    // let userLogin = this.appEventsService.subscribe("userLogin")
    // userLogin.subscriptions.add(userLogin.event.subscribe(data => {
    //   this.getWishListProducts()
    // }));

    // let userLogout = this.appEventsService.subscribe("userLogout")
    // userLogout.subscriptions.add(userLogout.event.subscribe(data => {
    //   this.wishListArray = []
    // }));
  }
  addRemoveWishProduct(id) {
    if (this.productIsInList(id))
      this.removeFromWishlist(id)
    else
      this.addToWishlist(id)
  }
  addToWishlist(id) {
    this.addWishList(id)
  }
  removeFromWishlist(id) {
    this.removeWishList(id)
  }
  addWishList(productId) {
    console.log(productId)
    this.wishListArray.push(productId);
    this.storage.set('wishListArray', this.wishListArray);
    this.appEventsService.publish('wishListUpdate', { id: productId, value: true });
    this.appToastService.toast("added to wish list")
  }

  removeWishList(id) {
    this.wishListArray.forEach((value, index) => {
      if (value == id) {
        this.wishListArray.splice(index, 1);
        this.storage.set('wishListArray', this.wishListArray);
      }
    });
    this.appEventsService.publish('wishListUpdate', { id: id, value: false });
    this.appToastService.toast("removed from wish list")
  }
  //============================================================================
  getWishListProducts(page = 1) {
    this.storage.get('wishListArray').then((val) => {
      if (val != null || val != undefined) {
        this.updateWishList(val)
      }
    });
  }
  updateWishList(resoponse) {
    this.wishListArray = resoponse
  }
  productIsInList(id) {
    let found = false;
    this.wishListArray.forEach(element => {
      if (element == id)
        found = true
    });
    return found
  }
}
