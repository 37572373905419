import { Injectable } from '@angular/core'
import { AppStorageService } from '../app-storage/app-storage.service'
import { AppEventsService } from '../app-events/app-events.service'
import { AppHttpService } from '../app-http/app-http.service'
import { AppRecentProductsService } from '../app-recentproducts/app-recent-products.service'
import { AppTranslationService } from '../app-translation/app-translation.service'
import { AppUserService } from '../app-user/app-user.service'
import { ConfigService } from '../config/config.service'
import { SharedDataService } from '../shared-data/shared-data.service'
import firebase from 'firebase/app'
import { ModalController } from '@ionic/angular'
import { IntroPage } from 'src/app/intro/intro.page'
import { AppCategoriesService } from '../app-categories/app-categories.service'
import { AppWishListService } from '../app-wishlist/app-wish-list.service'
import { AppNetworkService } from '../app-network/app-network.service'
@Injectable({
  providedIn: 'root'
})
export class AppOnStartService {

  constructor(
    public storage: AppStorageService,
    public modalCtrl: ModalController,
    public appHttp: AppHttpService,
    public config: ConfigService,
    public shared: SharedDataService,
    public appEventsService: AppEventsService,
    public appTranslationService: AppTranslationService,
    public appUserService: AppUserService,
    public appHttpService: AppHttpService,
    public appRecentProductsService: AppRecentProductsService,
    public appCategoriesService: AppCategoriesService,
    public appWishListService: AppWishListService,
    public appNetworkService: AppNetworkService,
  ) {
    console.log("onstart called");
  }
  //============================================================================
  getSettingsFromServer() {
    return this.appHttp.getSimpleHttp(this.config.yourSiteUrlString + '/wp-json/api/tc_settings/app_all_settings')
  }
  //============================================================================ 
  loadAppSetting() {
    return new Promise(resolve => {
      this.storage.get('appSettings').then((val) => {
        console.log(val)
        if (val == null) {
          this.getSettingsFromServer().then((response: any) => {
            if (response.status == "success") {
              let appSettings = {}
              appSettings = response.result
              this.onSettingsLoaded(appSettings)
              resolve(appSettings)
            }
          });
        }
        else {
          let appSettings = val
          this.onSettingsLoaded(appSettings)
          resolve(appSettings)
        }
      });
    });
  }


  //============================================================================
  //initalizing app after getting data from the server or local storage
  onSettingsLoaded(data) {
    // this.openIntroPage()
    this.storage.set("appSettings", data)
    this.config.defaultSettings(data)
    this.settingDirectionOfApp()
    this.loadAppTranslation()
    this.getBanners()
    this.getCategories()
    this.appWishListService.getWishListProducts()
    this.getVendorList()
    //this.appRecentProductsService.getRecentProducts()
    //firebase.initializeApp(this.config.firebaseConfig)
    this.checkingNewSettingsFromServer()
  }

  openIntroPage() {
    this.storage.get('firsttimeApp').then(async (val) => {
      console.log("firsttimeApp", val)
      if (this.config.showIntroPageBool && val == null) {
        this.storage.set('firsttimeApp', 'firstTime');
        let modal = await this.modalCtrl.create({
          component: IntroPage,
        });
        return modal.present();
      }
    });
  }
  //============================================================================
  settingDirectionOfApp() {
    //setting direction of the application
    document.documentElement.dir = this.config.appDirectionString
  }
  //============================================================================
  loadAppTranslation() {
    let url = 'assets/i18n/' + this.config.languageCodeString + ".json"
    this.appHttpService.angularHttpGet(url).then((data: any) => {
      let result = data.result
      let tempdata: { [k: string]: any } = {};
      Object.keys(result).forEach(key => {
        let value = result[key];
        let k = key.toLocaleLowerCase()
        tempdata[k] = value
      });

      this.appTranslationService.translationListArray = tempdata
    });
  }
  //============================================================================
  getBanners() {
    //getting all banners
    this.appHttpService.getSimpleHttp(this.config.yourSiteUrlString + '/wp-json/api/tc_settings/app_all_banners').then((data: any) => {
      if (data.status == "success") {
        this.shared.bannersArray = data.result.data
      }
    })
  }
  getNewestProducts() {
    let url = "products"
    url += "?per_page=" + this.config.perPageNumber

    this.appHttpService.getHttp(url).then((data: any) => {
      this.shared.tab1Array = data
    })
  }
  //============================================================================
  getCategories(page = 1) {
    let perPage = 99
    this.appHttpService.getHttp("products/categories?per_page=" + perPage + "&page=" + page).then((data: any) => {
      let resoponse = data
      if (resoponse.length == perPage)
        this.getCategories(++page)
      this.appCategoriesService.sortCategories(resoponse)
    });
  }
  //============================================================================
  getPages() {

    let url = this.config.yourSiteUrlString + '/wp-json/wp/v2/pages/';
    if (this.config.appSettingsRaw.about_page_id != undefined) {
      let ids = this.config.appSettingsRaw.about_page_id + "," + this.config.appSettingsRaw.refund_page_id + "," + this.config.appSettingsRaw.terms_page_id + "," + this.config.appSettingsRaw.privacy_page_id;
      url = url + '?include=' + ids;
    }
    //getting allpages from the server
    this.appHttpService.getSimpleHttp(url).then((data: any) => {
      if (data.success == 1) {
        let pages = data.result
        for (let value of pages) {
          if (this.config.appSettingsRaw.about_page_id == value.id) { this.shared.aboutUs = value.content.rendered; }
          if (this.config.appSettingsRaw.refund_page_id == value.id) this.shared.refundPolicy = value.content.rendered;
          if (this.config.appSettingsRaw.terms_page_id == value.id) this.shared.termServices = value.content.rendered;
          if (this.config.appSettingsRaw.privacy_page_id == value.id) this.shared.privacyPolicy = value.content.rendered;
        }
      }
    });
  }
  //============================================================================
  getVendorList() {
    if (this.config.showVendorInfoBool) {
      this.appHttpService.getSimpleHttp(this.config.yourSiteUrlString + "/wp-json/dokan/v1/stores").then((data: any) => {
        console.log(data)
        if (data.status == "success") {
          let ddata = data.result
          if (this.shared.vendors[0] == 1) this.shared.vendors = [];
          for (let d of ddata) {
            if (d.banner == false)
              d.banner = this.config.dummyImageString;//;
            if (d.store_name != '') d.name = d.store_name;
            else d.name = d.first_name + " " + d.last_name;
            console.log(d.name);
            this.shared.vendors.push(d);
          }
        }
      });
      //console.log("dokan is enabled");
    }
    else if (this.config.showWcVendorInfoBool) {
      this.appHttpService.getSimpleHttp(this.config.yourSiteUrlString + "/wp-json/api/tc_settings/app_featured_wcvendors_list").then((data: any) => {
        if (data.status == "success") {
          let ddata = data.result.data
          if (this.shared.vendors[0] == 1) this.shared.vendors = [];
          //console.log(data.data)
          if (ddata == null) ddata = [];
          for (let d of ddata) {
            if (d.banner == false)
              d.banner = this.config.dummyImageString;//;
            if (d.meta.pv_shop_name != '') d.name = d.meta.pv_shop_name;
            else d.name = d.first_name + " " + d.last_name;
            // console.log(d.name);
            this.shared.vendors.push(d);
          }
        }
      });
    }
    else if (this.config.showWcfmVendorInfoBool) {
      this.appHttpService.getSimpleHttp(this.config.yourSiteUrlString + "/wp-json/wcfmmp/v1/store-vendors").then((data: any) => {
        console.log(data)
        if (data.status == "success") {
          let ddata = data.result
          if (this.shared.vendors[0] == 1) this.shared.vendors = []
          console.log(ddata)
          if (ddata == null) ddata = []
          for (let d of ddata) {
            if (d.vendor_banner)
              d.banner = d.vendor_banner
            else
              d.banner = "assets/placeholder-wc.png"
            d.name = d.vendor_display_name
            // console.log(d.name)
            this.shared.vendors.push(d)
          }
        }
      });
    }
  }
  //============================================================================
  checkingNewSettingsFromServer() {
    this.getSettingsFromServer().then((data: any) => {
      if (data.status == "success") {
        let settings = data.result;
        this.storage.set("appSettings", settings)
      }
    });
  }
}
