import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import { AppStorageService } from 'src/services/app-storage/app-storage.service';
import { AppToastService } from 'src/services/app-toast/app-toast.service';
import { ConfigService } from 'src/services/config/config.service';
import { LoadingService } from 'src/services/loading/loading.service';


@Component({
  selector: 'app-woo-config-model',
  templateUrl: './woo-config-model.page.html',
  styleUrls: ['./woo-config-model.page.scss'],
})
export class WooConfigModelPage implements OnInit {

  public woo = {
    url: "",
    consumerKey: "",
    consumerSecret: ""
  }
  constructor(
    public storage: AppStorageService,
    public modalCtrl: ModalController,
    public appHttpService: AppHttpService,
    public config: ConfigService,
    public appToastService: AppToastService,
    public loading: LoadingService) {

  }

  submitForm() {
    //getting all banners
    this.woo.url = this.woo.url.endsWith('/') ? this.woo.url.slice(0, -1) : this.woo.url;
    this.appHttpService.getSimpleHttp(this.woo.url + '/wp-json/api/tc_settings/app_all_settings', true).then((data: any) => {
      if (data.status == "success") {
        this.setCredentialsAndRestart()

      }
      else {
        this.appToastService.toastWithCloseButton("Wordpress Plugin in not installed!")
      }

    })
    // this.setCredentialsAndRestart()
  }
  setCredentialsAndRestart() {
    this.loading.show()
    localStorage.siteUrl = this.woo.url
    localStorage.consumerKey = this.woo.consumerKey
    localStorage.consumerSecret = this.woo.consumerSecret
    this.storage.set("appSettings", null)
    setTimeout(() => {
      window.location.reload()
      this.loading.hide()
    }, 900)
  }
  ngOnInit() {
  }
  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
  }
}
