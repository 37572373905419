import { Injectable } from '@angular/core';
import { AppCartService } from '../app-cart/app-cart.service';
import { AppHttpService } from '../app-http/app-http.service';
import { ConfigService } from '../config/config.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class AppCurrencyService {

  currency: any;
  currencyList = [];
  constructor(
    public loading: LoadingService,
    public config: ConfigService,
    public appCartService: AppCartService,
    public appHttpService: AppHttpService) {
    this.getListOfCurrency();
  }
  getListOfCurrency() {
    this.appHttpService.postSimpleHttp(this.config.yourSiteUrlString + "/wp-json/api/tc_settings/app_all_currencies", {}, false).then((data: any) => {
      if (data.status == "success") {
        this.currencyList = data.result.data;
        this.currencyList.forEach(val => {

          if (val.symbol == localStorage.currency) {
            console.log(val.symbol);
            this.currency = val;
          }
        });
      }

    });
  }
  updateCurrency(value) {
    if (this.config.currencyCodeString != value.name) {
      this.loading.show()

      localStorage.currencyCodeString = value.name
      localStorage.currencySymbolString = value.symbol
      localStorage.currencyPositionString = value.position
      localStorage.currencyDecimalNumber = value.decimals

      this.appCartService.emptyCart();
      //this.appRecentProductsService.emptyRecentViewed();
      setTimeout(() => {
        window.location.reload()
        this.loading.hide()
      }, 900)
    }
  }

}
