import { Injectable } from '@angular/core';
import { AppCartService } from '../app-cart/app-cart.service';
import { AppHttpService } from '../app-http/app-http.service';
import { AppToastService } from '../app-toast/app-toast.service';
import { AppUserService } from '../app-user/app-user.service';
import { ConfigService } from '../config/config.service';
import { CouponService } from '../coupon/coupon.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { NavController } from '@ionic/angular';
import { AppTranslationService } from '../app-translation/app-translation.service';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { Device } from '@capacitor/device';
@Injectable({
  providedIn: 'root'
})
export class AppOrderService {
  //=======================================================

  billing = {
    first_name: '',
    last_name: '',
    company: 'null',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    email: '',
    phone: ''
  };
  billingCountryName = "";
  billingStateName = "";
  shipping = {
    first_name: '',
    last_name: '',
    company: 'null',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country: ''
  }
  shippingCountryName = "";
  shippingStateName = "";
  shipping_lines = [];
  listTaxRates = [];
  sameAddress = true;
  checkOutPageText = "Place Your Order";
  guest_status = 0;
  payment_method = ""
  payment_method_title = ""
  customerNotes = ""
  constructor(
    public appUserService: AppUserService,
    public appCartService: AppCartService,
    public appHttpService: AppHttpService,
    public appTranslationService: AppTranslationService,
    public couponService: CouponService,
    public config: ConfigService,
    public appToastService: AppToastService,
    private iab: InAppBrowser,
    public navCtrl: NavController,
    private spinnerDialog: SpinnerDialog
  ) {

  }

  addressIsFilled() {
    if (this.shipping.first_name == "") return false
    else return true
  }
  setOrderShippingAddress(data) {
    // this.shipping.first_name = data.delivery_first_name
    // this.shipping.last_name = data.delivery_last_name
    // this.shipping.address_1 = data.delivery_street_aadress
    // this.shipping.city = data.delivery_city
    // this.shipping.postcode = data.delivery_postcode
    // this.shipping.country = data.country
    // this.shipping.state = data.delivery_state
    // this.shipping.delivery_country_name = data.delivery_country_name
    // this.shipping.delivery_state_name = data.delivery_state_name
    // this.shipping.delivery_phone = data.delivery_phone
  }
  setOrderBillingAddress(data) {
    // this.orderDetails.billing_first_name = data.delivery_first_name
    // this.orderDetails.billing_last_name = data.delivery_last_name
    // this.orderDetails.billing_street_aadress = data.delivery_street_aadress
    // this.orderDetails.billing_city = data.delivery_city
    // this.orderDetails.billing_postcode = data.delivery_postcode
    // this.orderDetails.billing_country = data.delivery_country
    // this.orderDetails.billing_state = data.delivery_state
    // this.orderDetails.billing_country_name = data.delivery_country_name
    // this.orderDetails.billing_state_name = data.delivery_state_name
    // this.orderDetails.billing_phone = data.delivery_phone
    console.log(data);
  }

  setOrderCurrencyId(value) {

  }
  setOrderSession(value) {
    //this.orderDetails.session_id = value
  }
}
