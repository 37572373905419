import { Injectable } from '@angular/core'
import { AppStorageService } from '../app-storage/app-storage.service'
import { AppLogService } from '../app-log/app-log.service'
//import { AppPushNotificationService } from '../app-push-notification/app-push-notification.service'
import { LoadingService } from '../loading/loading.service'
import { AppEventsService } from '../app-events/app-events.service'
import { AppToastService } from '../app-toast/app-toast.service'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root'
})
export class AppUserService {

  public customerData: { [k: string]: any } = {}
  public guestSessionString: string = ""
  public guest_status = 0;
  constructor(
    public storage: AppStorageService,
    public loading: LoadingService,
    //public appPushNotificationService: AppPushNotificationService,
    public appLogService: AppLogService,
    public appEventsService: AppEventsService,
    public appToastService: AppToastService,
    public config: ConfigService
  ) {
    this.initalizeCustomerData()
  }

  initalizeCustomerData() {
    //getting logged in customer data 
    // this.storage.get('customerData').then((val) => {
    //   if (val != null || val != undefined)
    //     this.login(val)
    // })
    //console.log(localStorage.customerData)
    if (localStorage.customerData == undefined || localStorage.customerData == 'undefined') { }
    else this.login(JSON.parse(localStorage.customerData))
  }

  login(data) {
    this.customerData = data
    localStorage.customerData = JSON.stringify(data)
    console.log(this.customerData)
    this.appEventsService.publish('userLogin', "")
    this.setGuestSession("")
    //this.storage.set('customerData', data)
  }
  logOut() {
    this.removeCustomerData()
    this.appEventsService.publish('userLogout', "");
    // this.fb.logout()
  }
  removeCustomerData() {
    this.customerData = {}
    localStorage.customerData = undefined
    //this.storage.set('customerData', this.customerData)
  }
  changeGuestCheckoutStatus(value) {
    console.log("changeGuestCheckoutStatus", value);
    this.guest_status = value;
  }
  getGuestSession() {
    return this.guestSessionString
  }
  checkIfGuestSessionIsAvailable() {
    if (this.guestSessionString != "")
      return true
    else
      return false
  }
  setGuestSession(value) {
    this.guestSessionString = value
  }

  whoIsUser() {
    let result = ""
    if (this.customerData.id == undefined) result = "guest"
    else result = "customer"
    return result
  }

  getCustomerToken() {
    return this.customerData.token
  }

  userIsLogedIn() {
    if (this.whoIsUser() == "customer")
      return true
    else
      return false
  }
}
//================================= user data modal
// {
//  "cookie":"dasdasda ad asdad"
// 	"id": 3,
// 	"date_created": "2021-11-18T10:24:58",
// 	"date_created_gmt": "2021-11-18T10:24:58",
// 	"date_modified": null,
// 	"date_modified_gmt": null,
// 	"email": "hassan@gmail.com",
// 	"first_name": "hassan",
// 	"last_name": "mehboob2",
// 	"role": "subscriber",
// 	"username": "hassan",
// 	"billing": {
// 		"first_name": "",
// 		"last_name": "",
// 		"company": "",
// 		"address_1": "",
// 		"address_2": "",
// 		"city": "",
// 		"postcode": "",
// 		"country": "",
// 		"state": "",
// 		"email": "",
// 		"phone": ""
// 	},
// 	"shipping": {
// 		"first_name": "",
// 		"last_name": "",
// 		"company": "",
// 		"address_1": "",
// 		"address_2": "",
// 		"city": "",
// 		"postcode": "",
// 		"country": "",
// 		"state": "",
// 		"phone": ""
// 	},
// 	"is_paying_customer": false,
// 	"avatar_url": "https:\/\/secure.gravatar.com\/avatar\/c05687042c7771d480f3fb0d33ca8759?s=96&d=mm&r=g",
// 	"meta_data": [],
// 	"_links": {
// 		"self": [{
// 			"href": "https:\/\/.com\/wp-json\/wc\/v3\/customers\/3"
// 		}],
// 		"collection": [{
// 			"href": "https:\/\/.com\/wp-json\/wc\/v3\/customers"
// 		}]
// 	}
// }


