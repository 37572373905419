
import { Injectable } from "@angular/core"
import { Platform } from '@ionic/angular'
import { AppStorageService } from '../app-storage/app-storage.service'
import { AppLogService } from "../app-log/app-log.service"
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { OneSignal } from '@ionic-native/onesignal/ngx';

if (localStorage.darkModeBool == undefined) localStorage.darkModeBool = false
@Injectable()
//1.0 branch
export class ConfigService {

  

  public yourSiteUrlString: string = 'https://www.sommedicose.com';
  public consumerKey: string = "ck_72f1376a94ab772dc57b2ab0fa5f18df72d53317";
  public consumerSecret: string = "cs_ddef5835b33d6444be2ff12c3b45d68db476d064";

  public yourVendorUserNameString: string = ""
  public yourVendorPasswordString: string = ""
  public urlString: string = this.yourSiteUrlString + '/api/client/'
  public imgThumbnailUrlString: string = this.yourSiteUrlString + "/gallary/thumbnail"
  public imgMediumUrlString: string = this.yourSiteUrlString + "/gallary/medium"
  public imgLargeUrlString: string = this.yourSiteUrlString + "/gallary/large"
  public appDesignNumber: number = 1 //0 for n and 1 for r

  public dummyImageString: string = "assets/0.png"

  public showIntroPageBool: boolean = true //  0 to hide and 1 to show intro page
  public appInProductionBool: boolean = true //  0 to hide and 1 to show intro page
  public roundBordersBool: boolean = true //make elements border round

  //app theming
  public appThemeString: string = 'default'
  public darkModeBool: boolean = false

  // app info
  public appNameString: string = 'Som Medicose'
  public iosStoreUrlString: string = ""

  // login page settings
  public enableFacebookLoginBool: boolean = false
  public enableAddressMapBool: boolean = false
  public phoneAuthWithFirebaseBool: boolean = false
  // App Design Layout settings
  public homePageNumber: number = 0
  public bannerStyleNumber: number = 0
  public categoryPageNumber: number = 0
  public productCardStyleNumber: number = 0
  public productSlidesPerPageNumber: number = 2.1

  // App currency and language settings
  public languageCodeString: string = "en"
  public languageIdNumber: number = 1
  public currencyDecimalNumber: number = 2
  public currencyIdNumber: number = 1
  public currencyCodeString: string = "INR"
  public currencyPositionString: string = "left"
  public currencySymbolString: string = "&#36;"
  public appDirectionString: string = "ltr"

  // Initialize Firebase 
  public firebaseConfig: { [k: string]: any } = {}
  // google map key
  public googleMapApiString: string = ""

  // Push notification settings
  public onesignalAppIdString: string = ""
  public onesignalSenderIdString: string = ""
  // others
  public perPageNumber: number = 10;

  public currentPlatfromString: string = "web";
  public animationDurationNumber = 700 // in milisecounds
  public productCardColorsArray = ["#eaf3de", "#fbe5e2", "#d7f2fe", "#ffe9a5"]

  public introPageBool: boolean;
  public myOrdersPageBool: boolean;
  public newsPageBool: boolean;
  public wishListPageBool: boolean;
  public shippingAddressPageBool: boolean;
  public aboutUsPageBool: boolean;
  public contactUsPageBool: boolean;
  public editProfilePageBool: boolean;
  public settingPageBool: boolean;
  public addressPageBool: boolean;
  public downloadPageBool: boolean;
  public multiLanguageBool: boolean;
  public multiCurrencyBool: boolean;
  public showVendorInfoBool: boolean;
  public showWcfmVendorInfoBool: boolean;
  public showWcVendorInfoBool: boolean;
  public enableGeoFencingBool: boolean;
  public enableDeliveryTrackingBool: boolean;
  public enableWpPointRewardBool: boolean;
  public rateAppBool: boolean;
  public shareAppBool: boolean;
  public defaultIconsBool: boolean;
  public currentSettings: any;
  public addressString: string;
  public emailString: any;
  public latitudeString: any;
  public longitudeString: any;
  public phoneNoString: any;
  public newProductDurationNumber: number;
  public packgeNameString: any;
  public fbButtonBool: number;
  public siteUrlString: any;
  public filterMaxPriceNumber: number;
  public guestCheckOutBool: boolean;
  public onePageCheckoutBool: boolean = false;
  public afterShippingMethodCheckoutBool: boolean = false;
  public orderCancelButtonBool: boolean;
  public cancelOrderTimeNumber: number;
  public trackingUrlString: any;
  public appSettingsRaw: any;

  public productsArguments = "lang=" + localStorage.languageCodeString + "&currency=" + localStorage.currencyCodeString;//additional product arguments for query
  constructor(
    public storage: AppStorageService,
    public platform: Platform,
    public appLogService: AppLogService,
    public oneSignal: OneSignal
  ) {
    this.setCredentailsFromStorage()
    this.currentPlatfromString = Capacitor.getPlatform()
    this.setUserSettings()
    if (this.onesignalAppIdString != "") this.registerOneSignal()
  }
  setCredentailsFromStorage() {
    if (localStorage.siteUrl != undefined) {
      this.yourSiteUrlString = localStorage.siteUrl
      this.consumerKey = localStorage.consumerKey
      this.consumerSecret = localStorage.consumerSecret
    }
  }

  registerOneSignal() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.oneSignal.startInit(this.onesignalAppIdString, this.onesignalSenderIdString)
        this.oneSignal.endInit()
        this.oneSignal.handleNotificationReceived().subscribe(() => {
          // do something when notification is received
        });
      }
    });
  }
  defaultSettings(settings) {

    //   else if (element.setting_key == "language_id") this.languageIdNumber = parseInt(element.setting_value)
    // else if (element.setting_key == "language_code") this.languageCodeString = element.setting_value.toLocaleLowerCase()
    // else if (element.setting_key == "direction") this.appDirectionString = element.setting_value
    // else if (element.setting_key == "currency_id") this.currencyIdNumber = parseInt(element.setting_value)
    // else if (element.setting_key == "currency_code") this.currencyCodeString = element.setting_value
    // else if (element.setting_key == "currency_pos") this.currencyPositionString = element.setting_value
    // else if (element.setting_key == "currency_symbol") this.currencySymbolString = element.setting_value
    // else if (element.setting_key == "currency_decimals") this.currencyDecimalNumber = parseInt(element.setting_value)
    this.appSettingsRaw = settings
    this.homePageNumber = parseInt(settings.home_style);
    this.categoryPageNumber = parseInt(settings.category_style);
    this.bannerStyleNumber = parseInt(settings.banner_style)
    this.introPageBool = (settings.intro_page == "1") ? true : false;
    this.myOrdersPageBool = (settings.my_orders_page == "1") ? true : false;
    this.newsPageBool = (settings.news_page == "1") ? true : false;
    this.wishListPageBool = (settings.wish_list_page == "1") ? true : false;
    this.shippingAddressPageBool = (settings.shipping_address_page == "1") ? true : false;
    this.aboutUsPageBool = (settings.about_us_page == "1") ? true : false;
    this.contactUsPageBool = (settings.contact_us_page == "1") ? true : false;
    this.editProfilePageBool = (settings.edit_profile_page == "1") ? true : false;

    this.settingPageBool = (settings.setting_page == "1") ? true : false;
    //this.currency = settings.currency_symbol;
    this.productCardStyleNumber = parseInt(settings.card_style)
    //this.footerShowHide = parseInt(settings.footer_button);
    this.addressPageBool = (settings.bill_ship_info == "1") ? true : false;
    this.downloadPageBool = (settings.downloads == "1") ? true : false;
    this.multiLanguageBool = (settings.wpml_enabled == "1") ? true : false;

    this.multiCurrencyBool = (settings.wp_multi_currency == "1") ? true : false;
    this.showVendorInfoBool = (settings.mvf_enabled == "1") ? true : false;
    this.showWcVendorInfoBool = (settings.mvf_enabled == "2") ? true : false;
    this.showWcfmVendorInfoBool = (settings.mvf_enabled == "3") ? true : false;
    this.enableGeoFencingBool = (settings.geo_fencing == "1") ? true : false;
    this.enableDeliveryTrackingBool = (settings.delivery_tracking == "1") ? true : false;
    this.enableWpPointRewardBool = (settings.wp_point_reward == "1") ? true : false;

    this.rateAppBool = (settings.rate_app == "1") ? true : false;
    this.shareAppBool = (settings.share_app == "1") ? true : false;
    this.defaultIconsBool = (settings.sidebar_menu_icon == "1") ? false : true;
    this.addressString = settings.address + ', ' + settings.city + ', ' + settings.state + ' ' + settings.zip + ', ' + settings.country;
    this.emailString = settings.contact_us_email;
    this.latitudeString = settings.latitude;
    this.longitudeString = settings.longitude;
    this.phoneNoString = settings.phone_no;
    this.newProductDurationNumber = parseInt(settings.new_product_duration);

    this.iosStoreUrlString = this.packgeNameString = settings.package_name;
    //this.appNameString = settings.app_name;

    this.fbButtonBool = parseInt(settings.facebook_login);
    this.siteUrlString = settings.site_url;
    this.filterMaxPriceNumber = parseInt(settings.filter_max_price);
    this.guestCheckOutBool = (settings.checkout_process == "yes") ? true : false;
    this.onePageCheckoutBool = (settings.one_page_checkout == "1") ? true : false;
    this.afterShippingMethodCheckoutBool = (settings.one_page_checkout == "0") ? true : false;
    this.orderCancelButtonBool = (settings.cancel_order_button == "1") ? true : false;

    this.cancelOrderTimeNumber = parseInt(settings.cancel_order_hours);
    this.trackingUrlString = settings.tracking_url;


    this.setLanguageAndCurrenyData();
  }

  setLanguageAndCurrenyData() {
    if (localStorage.languageCodeString == undefined) {
      localStorage.languageCodeString = this.languageCodeString
      localStorage.languageIdNumber = this.languageIdNumber
      localStorage.currencyDecimalNumber = this.currencyDecimalNumber
      localStorage.currencyIdNumber = this.currencyIdNumber
      localStorage.currencyCodeString = this.currencyCodeString
      localStorage.currencyPositionString = this.currencyPositionString
      localStorage.currencySymbolString = this.currencySymbolString
      localStorage.appDirectionString = this.appDirectionString
    }
    else {
      this.languageCodeString = localStorage.languageCodeString.toLocaleLowerCase()
      this.languageIdNumber = parseInt(localStorage.languageIdNumber)
      this.currencyDecimalNumber = parseInt(localStorage.currencyDecimalNumber)
      this.currencyIdNumber = parseInt(localStorage.currencyIdNumber)
      this.currencyCodeString = localStorage.currencyCodeString
      this.currencyPositionString = localStorage.currencyPositionString
      this.currencySymbolString = localStorage.currencySymbolString
      this.appDirectionString = localStorage.appDirectionString
    }

  }

  setUserSettings() {
    if (localStorage.darkModeBool == "true")
      this.enableDarkMode(true)
    else
      this.enableDarkMode(false)
  }

  enableDarkMode(bool) {
    this.darkModeBool = bool
    this.changeStatusBarColor()
    if (bool) localStorage.darkModeBool = "true"
    else localStorage.darkModeBool = "false"
  }

  changeStatusBarColor() {
    if (Capacitor.isNativePlatform()) {
      if (this.darkModeBool) {
        StatusBar.setStyle({ style: Style.Dark })
        StatusBar.setBackgroundColor({ color: "#000000" })
      }
      else {
        StatusBar.setStyle({ style: Style.Light })
        StatusBar.setBackgroundColor({ color: "#ffffff" })
      }
    }
  }

  transformCurrency(value) {
    let currency = this.currencySymbolString;
    let decimals = this.currencyDecimalNumber;
    let currecnyPos = this.currencyPositionString;

    let priceFixed = parseFloat(value).toFixed(decimals);
    //let priceFixed = value;

    if (priceFixed.toString() == 'NaN') {

      if (currecnyPos == 'left')
        return currency + "" + value;
      else
        return value + " " + currency;
    }
    else {
      if (currecnyPos == 'left')
        return currency + "" + priceFixed;
      else
        return priceFixed + "" + currency;
    }
  }
}

