import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/services/loading/loading.service';
import { ConfigService } from 'src/services/config/config.service';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import { ModalController } from '@ionic/angular';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import { AppCartService } from 'src/services/app-cart/app-cart.service';
import { AppWishListService } from 'src/services/app-wishlist/app-wish-list.service';
import { AppRecentProductsService } from 'src/services/app-recentproducts/app-recent-products.service';
import { AppLogService } from 'src/services/app-log/app-log.service';
import { AppCurrencyService } from 'src/services/app-currency/app-currency.service';


@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.page.html',
  styleUrls: ['./currency-list.page.scss'],
})
export class CurrencyListPage implements OnInit {

  constructor(
    public appCurrencyService: AppCurrencyService,
    public config: ConfigService,
    public modalCtrl: ModalController) {
  }

  getSelectedColor(c) {
    if (c.name == this.config.currencyCodeString) {
      return 'primary'
    }
  }
  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
