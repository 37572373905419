
import { Injectable } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { AppEventsService } from 'src/services/app-events/app-events.service';
import { AppHttpService } from '../app-http/app-http.service';
import { ConfigService } from '../config/config.service';

@Injectable()
export class SharedDataService {

  public bannersArray: any = [1, 1, 1, 1, 1];
  public tab1Array = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public tab2Array = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public tab3Array = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public flashSaleProductsArray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];


  public privacyPolicy = "";
  public termServices = "";
  public refundPolicy = "";
  public aboutUs = "";

  public tempdata: { [k: string]: any } = {};
  public currentOpenedModel: any = null;
  public singleProductPageDataArray = [];
  public singlePostData: any;
  public myOrderDetialPageData: any;
  public lab = false;
  public primaryHexColor = "#3980ff";
  public productDeleteId: any;
  public productCardCounterNumber = 0
  public storePageData = [];
  vendors = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  constructor(
    public appEventsService: AppEventsService,
    public platform: Platform,
    public config: ConfigService,
    public menuCtrl: MenuController,
    public appHttpService: AppHttpService
  ) {

  }
  searchProductInLoadedData(id) {
    let findData = null

    this.singleProductPageDataArray.forEach(element => {
      if (id == element.id)
        findData = element
    });
    return findData
  }
  getLastBanner() {
    return this.bannersArray[this.bannersArray.length - 1].banners_image
  }
  getContentPages() {
    let url = this.config.yourSiteUrlString + '/wp-json/wp/v2/pages/';
    if (this.config.appSettingsRaw.about_page_id != undefined) {

      let ids = this.config.appSettingsRaw.about_page_id + "," +
        this.config.appSettingsRaw.refund_page_id + "," +
        this.config.appSettingsRaw.terms_page_id + "," +
        this.config.appSettingsRaw.privacy_page_id;

      url = url + '?include=' + ids;
    }
    this.appHttpService.getSimpleHttp(url).then((data: any) => {
      if (data.status == "success") {
        let d = data.result;
        for (let value of d) {
          if (this.config.appSettingsRaw.about_page_id == value.id) { this.aboutUs = value.content.rendered; }
          if (this.config.appSettingsRaw.refund_page_id == value.id) this.refundPolicy = value.content.rendered;
          if (this.config.appSettingsRaw.terms_page_id == value.id) this.termServices = value.content.rendered;
          if (this.config.appSettingsRaw.privacy_page_id == value.id) this.privacyPolicy = value.content.rendered;
        }
      }
    })
  }
  toggleMenu() {
    this.menuCtrl.toggle("mainMenu");
  }
  public splashScreenHide = false;

  showAd() {
    //this.loading.autoHide(2000);
    this.appEventsService.publish('showAd', "");
  }


  getProductRatingPercentage(rating: any) {
    let val = (parseFloat(rating) * 100) / 5;
    return val + '%'
  }

}
