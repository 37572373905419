import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { NavController } from '@ionic/angular';
import { ConfigService } from 'src/services/config/config.service';
import { AppCartService } from '../app-cart/app-cart.service';
import { AppHttpService } from '../app-http/app-http.service';
import { AppOrderService } from '../app-order/app-order.service';
import { AppToastService } from '../app-toast/app-toast.service';
import { AppUserService } from '../app-user/app-user.service';
import { CouponService } from '../coupon/coupon.service';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {

  constructor(
    public inAppBrowser: InAppBrowser,
    public config: ConfigService,
    public navCtrl: NavController,
    public appHttpService: AppHttpService,
    public couponService: CouponService,
    public appOrderService: AppOrderService,
    public appToastService: AppToastService,
    public appUserService: AppUserService,
    public appCartService: AppCartService
  ) {

  }

  //=================================================================================================================================
  openCheckoutWebview() {
    let data: { [k: string]: any } = {}

    if (this.appUserService.userIsLogedIn()) data.customer_id = this.appUserService.customerData.id;
    else data.customer_id = 0

    if (this.appUserService.userIsLogedIn()) data.token = this.appUserService.customerData.cookie;
    else data.token = null

    if (!this.config.afterShippingMethodCheckoutBool) {
      data.payment_method = this.appOrderService.payment_method
      data.payment_method_title = this.appOrderService.payment_method_title
    }

    if (!this.config.onePageCheckoutBool) {
      data.shipping_ids = this.appOrderService.shipping_lines
      data.sameAddress = !this.appOrderService.sameAddress
    }

    data.billing_info = this.appOrderService.billing
    data.shipping_info = this.appOrderService.shipping
    data.products = this.appCartService.getProducts()
    data.coupons = this.couponService.getCoupons()
    data.customer_note = this.appOrderService.customerNotes
    data.platform = this.config.currentPlatfromString
    data.one_page = this.config.appSettingsRaw.one_page_checkout;

    let headerColor = "#ffffff"
    let textColor = "#000000"
    if (this.config.darkModeBool) {
      headerColor = "#1e1e1e"
      textColor = "#ffffff"
    }
    let options: InAppBrowserOptions = {
      toolbarposition: "top",
      location: 'yes',//Or 'no' 
      clearcache: 'yes',
      clearsessioncache: 'yes',
      hideurlbar: "yes",
      zoom: 'no',//Android only ,shows browser zoom controls 
      closebuttoncolor: textColor,
      toolbarcolor: headerColor,
      fullscreen: "no",
      toolbar: 'yes', //iOS only 
    };

    this.appHttpService.postSimpleHttp(this.config.yourSiteUrlString + '/wp-json/api/tc_settings/app_data_link', data, true).then((data: any) => {
      if (data.status == "success") {
        const b = this.inAppBrowser.create(this.config.yourSiteUrlString + "/mobile-checkout/?order_id=" + data.result, '_blank', options);
        let orderPlaced = false;
        b.on('loadstart').subscribe(res => {
          if (res.url.indexOf('order-received') != -1) {
            console.log(res.url);
            orderPlaced = true;
            b.close();
            this.navCtrl.navigateRoot("thank-you")
          } else if (res.url.indexOf('cancel_order=true') != -1) {
            b.close();
          }

        });
        b.on('loadstop').subscribe(res => {
          console.log('loadstop');
        });

        b.on('exit').subscribe(res => {
          if (orderPlaced) this.navCtrl.navigateRoot("thank-you")
          else
            this.appToastService.toast("Order Cancelled");
        });
      }
    });
  }

}
