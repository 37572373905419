import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AppCategoriesService {


  public demoData: any = [
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString },
    { name: "--", imageSrc: this.config.dummyImageString }
  ];

  public allCategoriesArray: any = JSON.parse(JSON.stringify(this.demoData));;
  public categoriesArray: any = JSON.parse(JSON.stringify(this.demoData));;
  public subCategoriesArray: any = JSON.parse(JSON.stringify(this.demoData));;

  constructor(
    public config: ConfigService
  ) { }

  sortCategories(resoponse: any) {
    if (this.allCategoriesArray[0].name == "--") {
      this.allCategoriesArray = []
      this.categoriesArray = []
      this.subCategoriesArray = []
    }
    for (let value of resoponse) {
      if (value.image == null) value.imageSrc = this.config.dummyImageString
      else value.imageSrc = value.image.src

      if (value.count != 0) {
        value.name = this.decodeHtml(value.name);
        this.allCategoriesArray.push(value)
        if (value.parent == 0)
          this.categoriesArray.push(value)
        else
          this.subCategoriesArray.push(value)
      }
    }
  }
  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  getCategories(parentId) {
    let cat = [];
    for (let value of this.allCategoriesArray) {
      if (value.parent == parentId) {
        cat.push(value);
      }
    }
    return cat;
  }

  checkCategoriesHasChild(parentId) {
    if (this.getCategories(parentId).length == 0) return false
    else return true
  }

  getCategoryName(id) {
    let name = ""
    for (let value of this.allCategoriesArray) {
      if (value.id == id) { name = value.name; }
    }
    return name;
  }
  getCategoryObject(id) {
    let obj: any = {}
    for (let value of this.allCategoriesArray) {
      if (value.id == id) { obj = value }
    }
    return obj;
  }
  getCategoriesPageItems(parent) {
    let c = [];
    if (parent == undefined)
      c = this.categoriesArray;
    else {
      for (let v of this.allCategoriesArray) {
        if (v.parent == parent) {
          c.push(v);
        }
      }
    }
    return c;
  }
}
