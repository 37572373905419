import { Injectable } from '@angular/core';
import { AppHttpService } from '../app-http/app-http.service';
import { AppStorageService } from '../app-storage/app-storage.service';
import { AppUserService } from '../app-user/app-user.service';
import { ConfigService } from '../config/config.service';
import { SharedDataService } from '../shared-data/shared-data.service';
import { Capacitor } from '@capacitor/core';
import { AppToastService } from '../app-toast/app-toast.service';
import { NavController } from '@ionic/angular';
import { CouponService } from '../coupon/coupon.service';
import { AppEventsService } from '../app-events/app-events.service';
import { Haptics } from '@capacitor/haptics';
import { AppAlertService } from 'src/services/app-alert/app-alert.service';
import { LoadingService } from '../loading/loading.service';
@Injectable({
  providedIn: 'root'
})

export class AppCartService {
  public cartProductsArray = new Array()
  public cartquantityNumber = 0.0
  public cartTaxFloat = 0.0
  public cartSubTotalFloat = 0.0
  public cartTotalFloat = 0.0
  public cartDiscountFloat = 0.0

  constructor(
    public storage: AppStorageService,
    public config: ConfigService,
    public shared: SharedDataService,
    public appUserService: AppUserService,
    public appHttpService: AppHttpService,
    public appToastService: AppToastService,
    public appAlertService: AppAlertService,
    public nav: NavController,
    public couponService: CouponService,
    public appEventsService: AppEventsService,
    public loading: LoadingService,
  ) {
    this.registerEvents()
    this.getCartFromStorage()
  }

  registerEvents() {
    let userLogin = this.appEventsService.subscribe("userLogin")
    userLogin.subscriptions.add(userLogin.event.subscribe(data => {
      this.getCartFromStorage();
    }));

    let userLogout = this.appEventsService.subscribe("userLogout")
    userLogout.subscriptions.add(userLogout.event.subscribe(data => {
      this.emptyCart()
    }));
  }

  emptyCart() {
    this.cartProductsArray = []
    this.saveCartToLocalStorage(this.cartProductsArray);
    this.cartTotalItems()
  }
  saveCartToLocalStorage(array) {
    this.storage.set("cartProductsArray", array)
  }
  openCartPage() {
    this.nav.navigateForward("cart")
  }
  getCartFromStorage() {
    //getting cart from local storage
    this.storage.get('cartProductsArray').then((val) => {
      if (val != null) this.cartProductsArray = val;
      this.cartTotalItems();
    });
  }
  //adding into cart array products
  addToCart(product, variation, quantity: any, metaData: any, quantityType = null) {

    let p: { [k: string]: any } = {};
    if (quantity == null || quantity == "null") {
      p.quantity = 1;
    }
    else {
      p.quantity = quantity;
      console.log(quantity);
      console.log("quantity not null");
    };

    //checking if variation is out of stock
    if (variation != null) {
      if (variation.stock_status == "outofstock") {
        this.appToastService.toastMiddle("OUT OF STOCK")
        return 0;
      }
    }
    if (product.stock_status == "outofstock") {
      this.appToastService.toastMiddle("OUT OF STOCK")
      return 0;
    }
    if (!this.checkCart(product, quantity)) {
      return 0;
    }
    if (metaData == null) {
      if (this.alreadyInCart(product, variation, quantity, quantityType)) {
        return 0;
      }
    }
    if (quantityType == "quantityMinus") {
      return 0
    }
    p.product_id = product.id;
    p.name = product.name;

    var seconds = new Date().getTime();
    p.cart_id = product.id + seconds;
    if (product.images.length != 0) {
      p.image = product.images[0].src;
    }
    else {
      p.image = "assets/woocommerce-placeholder.png"
    }
    //console.log(p.image)
    p.stock_quantity = product.stock_quantity;
    p.tax_class = product.tax_class;
    p.tax_status = product.tax_status;
    p.price = product.price;
    p.price_html = product.price_html;
    p.subtotal = parseFloat(product.price) * parseInt(p.quantity);
    p.total = parseFloat(product.price) * parseInt(p.quantity);
    p.on_sale = product.on_sale;
    p.categories = product.categories;

    let metaPrice = 0
    if (metaData != null) {
      metaData.forEach(element => {
        if (element.price)
          metaPrice += parseFloat(element.price);
        console.log(element.price)
      });
      console.log(metaPrice)
      p.subtotal += metaPrice * parseInt(p.quantity);
      p.total += metaPrice * parseInt(p.quantity);
      p.meta_data = metaData;
    }
    p.sold_individually = product.sold_individually;

    if (product.type == 'variable' && variation != null) {
      p.variation_id = variation.id;
      p.price = parseFloat(variation.price);
      p.subtotal = parseFloat(variation.price) * parseInt(p.quantity);
      p.total = parseFloat(variation.price) * parseInt(p.quantity);
      p.name = variation.name;
      p.stock_quantity = variation.stock_quantity;
      p.tax_status = variation.tax_status;

      try {
        if (variation.image) p.image = variation.image.src;
        else p.image = variation.images[0].src;
      } catch (error) {
        console.log(error);
      }

    }
    console.log(p);
    this.cartProductsArray.push(p);
    this.saveCartToLocalStorage(this.cartProductsArray);

    this.cartTotalItems();
    // console.log(this.cartProductsArray);
    //console.log(this.cartProductsArray);
    this.appToastService.toastMiddle("Added To Cart!")
    return 1
  }
  checkCart(p, quantity) {
    let name = null;
    let onlyOneAllowed = true;
    let quantityCheck = true;
    //check for only one item is allowed
    for (let value of this.cartProductsArray) {
      if (value.sold_individually == true && p.id == value.product_id) {
        onlyOneAllowed = false;
        name = value.name;
      }
    }
    if (onlyOneAllowed == false) {
      this.appAlertService.showAlertWithTitle(name, "Only One Item Allowed");
    }

    //check for product quantity
    if (quantity == null) quantity = 1;

    if (p.stock_quantity == null || p.stock_quantity > quantity) quantityCheck = true;
    else if (p.stock_quantity < quantity) {
      quantityCheck = false;
      this.appToastService.toastMiddle("Product Quantity is Limited!")
    }

    if (onlyOneAllowed && quantityCheck) return true;
    else return false;
  }
  alreadyInCart(p, variation, quantity, quantityType) {

    let count = 0;
    for (let value of this.cartProductsArray) {
      //console.log(value.variation_id + "  " + vId.id + "  " + value.product_id + "  " + p.id);
      if (p.type != 'variable' && value.product_id == p.id) {
        count++;

        if (quantityType == "quantityPlus") {
          value.quantity++;
        }
        else if (quantityType == "quantityMinus") {
          if (value.quantity == 1) {
            this.removeCartItemWithProductId(p.id)
          }
          else {
            value.quantity--
          }
        }
        else if (quantityType == null) {
          value.quantity = parseInt(value.quantity) + parseInt(quantity);
        }

      }
      else if (value.product_id == p.id && value.variation_id == variation.id) {
        count++;
        value.quantity = parseInt(value.quantity) + parseInt(quantity);
      }
      value.subtotal = parseFloat(value.price) * parseInt(value.quantity);
      value.total = parseFloat(value.price) * parseInt(p.quantity);
    }

    this.saveCartToLocalStorage(this.cartProductsArray);
    this.cartTotalItems();
    if (count != 0) return true;
    else return false;
  }

  deleteProductFromCart(id, combinationId = null) {
    this.cartProductsArray.forEach((value, index) => {

      if (value.cart_id == id) {
        this.cartProductsArray.splice(index, 1);
        console.log("removing" + id);
      }
    });
    this.updateCart();
  }

  calculateFinalPrice() {
    this.cartTotalFloat = 0;
    //this.cartTaxFloat = 0
    this.cartSubTotalFloat = 0
    //this.cartDiscountFloat = 0

    var total = 0;
    var subTotal = 0;
    this.cartProductsArray.forEach((value, index) => {
      subTotal = subTotal + value.subtotal
      total = total + value.total;
    });
    this.cartSubTotalFloat = subTotal
    this.applyCoupon()
    this.cartTotalFloat = total
  }

  removeCartItem(id) {
    this.cartProductsArray.forEach((value, index) => {
      if (value.cart_id == id) {
        this.cartProductsArray.splice(index, 1);
        console.log("removing" + id);
      }
    });
    this.updateCart();

  }
  removeCartItemWithProductId(id) {
    this.cartProductsArray.forEach((value, index) => {
      if (value.product_id == id) {
        this.cartProductsArray.splice(index, 1);
        console.log("removing" + id);
      }
    });
    this.updateCart();

  }
  public loadingServerData = false

  updateCart() {

    if (this.cartProductsArray.length != 0) { this.loading.show(); this.loadingServerData = false; }
    let count = 0;
    this.cartProductsArray.forEach((value, index) => {

      let id = value.product_id; if (value.variation_id != undefined) id = value.variation_id;
      this.appHttpService.getHttp("products/" + id).then((data: any) => {
        count++;
        let p = data
        if (p.id == undefined) { this.cartProductsArray.splice(index, 1); }
        else if (p.status == 'trash') { this.cartProductsArray.splice(index, 1); }
        else {
          value.price = p.price;
          value.name = p.name;
          value.stock_quantity = p.stock_quantity;
          value.tax_status = p.tax_status;
          //value.image = p.images[0].src;
          value.tax_class = p.tax_class;
          value.tax_status = p.tax_status;
          value.on_sale = p.on_sale;
          value.categories = p.categories;

          if (p.stock_quantity == null) { }
          else if (p.stock_quantity < value.quantity) value.quantity = p.stock_quantity;
          else if (p.stock_status != 'instock') { this.cartProductsArray.splice(index, 1); }

          value.subtotal = parseFloat(value.price) * value.quantity;
          value.total = parseFloat(value.price) * value.quantity;
        }
        if (count == this.cartProductsArray.length) {
          this.changingCart();
          this.loading.hide();
          this.loadingServerData = true;
        }
      });
    });
  }
  //==========================================================================
  changingCart() {
    //setting cart to local storage
    this.storage.set('cartProductsArray', this.cartProductsArray)
    this.couponService.couponArray.forEach((value) => {
      this.cartProductsArray = this.couponService.apply(value, this.cartProductsArray);
    });

    this.cartTotalItems();
    this.calculateFinalPrice()
  }
  getProductCartQuantity(id) {
    let quantity = 0
    this.cartProductsArray.forEach(element => {
      if (element.product_id == id) {
        quantity = element.quantity;
      }
    });
    return quantity
  }
  //Function calcualte the total items of cart
  cartTotalItems() {
    let total = 0
    // for (let value of this.cartProductsArray) {
    //   total += parseInt(value.quantity);
    // }
    total = this.cartProductsArray.length
    this.cartquantityNumber = total;
    this.calculateFinalPrice()
    //console.log("updated", this.cartquantity);
    return total;
  };

  //============================================== coupon =================================
  public couponObject: any = null
  checkCouponAvalability(value) {
    let url = "coupon"
    url += "?coupon_code=" + value
    this.appHttpService.postHttp(url, {}, true).then((data: any) => {
      this.couponObject = data
      this.calculateFinalPrice()
      this.appToastService.toastMiddle("Coupon Applied!")
    })
  }
  productsTotal() {
    let total = 0;
    for (let value of this.cartProductsArray) {
      total = total + parseFloat(value.total);
    }
    return total;
  }
  getProductsSubtotal() {
    let total = 0;
    for (let value of this.cartProductsArray) {
      total = total + parseFloat(value.subtotal);
    }
    return total;
  }
  applyCoupon() {
    if (this.couponObject) {
      if (this.couponObject.type == "fixed") {
        this.cartDiscountFloat = this.couponObject.amount
      }
      if (this.couponObject.type == "percentage") {
        let discount = (this.cartSubTotalFloat / 100) * this.couponObject.amount
        this.cartDiscountFloat = discount
      }
    }
  }

  getProductQuantity(id) {
    let quantity = 0;
    this.cartProductsArray.forEach(element => {
      if (element.product_id == id) {
        quantity = element.quantity
      }
    });
    return quantity
  }

  getProducts() {
    var data = [];
    for (let v of this.cartProductsArray) {
      var obj = { quantity: v.quantity, product_id: v.product_id, total: v.total.toString(), price: v.price.toString() };
      if (v.variation_id) Object.assign(obj, { variation_id: v.variation_id })
      if (v.meta_data) Object.assign(obj, { meta_data: v.meta_data })
      data.push(obj);
    }
    return data;
  }
}
