
import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { AppEventsService } from '../app-events/app-events.service'
import { AppLogService } from '../app-log/app-log.service'
import { AppToastService } from '../app-toast/app-toast.service'
import { ConfigService } from '../config/config.service'
import { GetDeviceIdService } from '../get-device-id/get-device-id.service'
import { GetIpAddressService } from '../get-ip-Address/get-ip-address.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { HTTP } from '@ionic-native/http/ngx'
import { LoadingService } from '../loading/loading.service'
import { AppUserService } from '../app-user/app-user.service'

declare var WooCommerceAPI: any;

@Injectable({
  providedIn: 'root'
})
export class AppHttpService {

  consumerKey: string;
  consumerSecret: string;
  Woocommerce: any
  successTextString = "success"
  errorTextString = "error"

  constructor(
    public appEventsService: AppEventsService,
    public getIpAddressService: GetIpAddressService,
    public getDeviceIdService: GetDeviceIdService,
    public config: ConfigService,
    public platform: Platform,
    public appLogService: AppLogService,
    public appToastService: AppToastService,
    public angularHttp: HttpClient,
    private httpNative: HTTP,
    public loading: LoadingService,
    public appUserService: AppUserService
  ) {
    this.Woocommerce = WooCommerceAPI.WooCommerceAPI({
      url: this.config.yourSiteUrlString,
      consumerKey: this.config.consumerKey,
      consumerSecret: this.config.consumerSecret,
      wpAPI: true,
      queryStringAuth: true,
      version: 'wc/v3'
    });
  }

  getKeyByValue(object, value) {
    //return Object.keys(object).find(key => object[key] === value);

    for (var i in object) {
      if (!!object[i] && typeof (object[i]) == "object") {
        console.log(i, object[i]);
        this.getKeyByValue(object[i], value);
      } else {
        if (i == value)

          return object[i]
        console.log(i, object[i]);
      }
    }
    return null
  }
  findValueAndShowToast(json, value) {
    let result = this.getKeyByValue(json, value)
    console.log(result)
    if (result) {
      this.appToastService.toastError(result)
    }
  }
  //============================================================================
  onReceiveError200(type, requestUrl, postData = {}, response) {
    let info = "Error : Http : " + type + " : "
    this.appLogService.log(info + requestUrl, postData, response)
    //this.appToastService.toastError(info + requestUrl, response)
    //this.appToastService.toastError(response.message)
    //this.findValueAndShowToast(response, "message")
    console.log(info + requestUrl, postData, response)
  }
  //============================================================================
  onReceiveSuccess(type, requestUrl, postData = {}, response) {
    let info = "Response : Http : " + type + " : "
    this.appLogService.log(info + requestUrl, postData, response)
    //console.log(info + requestUrl, response)
  }
  //============================================================================
  onReceiveErrorNot200(type, requestUrl, postData = {}, response) {
    let info = "Error : Http : " + type + " : "
    console.log(info + requestUrl, postData, response)
    this.appLogService.log(info + requestUrl, postData, response)
    //this.appToastService.toastError(info + requestUrl)
    //this.appToastService.toastError(response.error.message)
    //this.findValueAndShowToast(response, "message")
  }
  //======================================================
  compareStrings(s1, s2) {
    if (s1.toLocaleLowerCase() == s2.toLocaleLowerCase()) return true
    else return false
  }
  //===================================================== Get Request ===================================
  getHeadersForHttp(url) {
    let headers = {}
    if (url.indexOf('wcfmmp') != -1) {
      let Authorization = "Basic " + btoa(this.config.yourVendorUserNameString + ':' + this.config.yourVendorPasswordString)
      headers = Object.assign({ Authorization: Authorization }, headers)
    }
    if (url.indexOf('tc_wallet') != -1) {
      headers = Object.assign({ "get-cookie": this.appUserService.customerData.cookie }, headers)
    }
    // if (this.appUserService.whoIsUser() == "customer") {
    //   let Authorization = "Bearer " + this.appUserService.getCustomerToken()
    //   headers = Object.assign({ Authorization: Authorization }, headers)
    // }
    return headers;
  }
  //===================================================== Get simpple Request ===================================
   getSimpleHttp(req, loader = false, showAlert = false) {
    let customHeaders = this.getHeadersForHttp(req)
    const httpOptions = {
      headers: new HttpHeaders(customHeaders)
    };
    return new Promise(resolve => {
      if (loader) this.loading.show()
      if (this.platform.is('cordova')) {
        this.httpNative.get(req, {}, customHeaders)
          .then((data: any) => {
            if (loader) this.loading.hide()
            let response = this.createResponseAndRegisterError(JSON.parse(data.data), "ok", "Get", req, {})
            resolve(response);
          })
          .catch(error => {
            if (loader) this.loading.hide()
            console.log(error.error)
            let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "Get", req, {})
            resolve(response);
          });
      }
      else {
        this.angularHttp.get(req, httpOptions).subscribe((data: any) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(data, "ok", "Get", req, {})
          resolve(response);
          if (loader) this.loading.hide()
        }, (error) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(error.error, "error", "Get", req, {})
          resolve(response);
        });
      }
    });
  }

  //================================================ Post Request ===============================================
  postSimpleHttp(req, postData, loader = false) {
    let customHeaders = this.getHeadersForHttp(req)
    const httpOptions = {
      headers: new HttpHeaders(customHeaders)
    };

    return new Promise(resolve => {
      if (loader) this.loading.show()
      if (this.platform.is('cordova')) {
        this.httpNative.setDataSerializer("json");
        this.httpNative.post(req, postData, customHeaders)
          .then(data => {
            if (loader) this.loading.hide()
            let response = this.createResponseAndRegisterError(JSON.parse(data.data), "ok", "POST", req, postData)
            resolve(response);
          })
          .catch(error => {
            if (loader) this.loading.hide()
            console.log(error)
            error.error = JSON.parse(error.error)
            let response = this.createResponseAndRegisterError(error, "error", "POST", req, postData)
            resolve(response);
          });
      }
      else {
        this.angularHttp.post(req, postData, httpOptions).subscribe((data: any) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(data, "ok", "POST", req, postData)
          resolve(response);
        }, (error) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(error, "error", "POST", req, postData)
          resolve(response);
        });
      }
    });
  }

  generateWooUrl(type, endpoint) {

    let args = "lang=" + this.config.languageCodeString + "&currency=" + this.config.currencyCodeString

    if (endpoint.indexOf('product') != -1) {
      args = args + "&status=publish";
    }

    if (endpoint.indexOf('?') != -1) {
      endpoint += "&" + args
    }
    else {
      endpoint += "?" + args
    }

    let start = "&"

    if (type == "put") start = "?";
    let r = this.Woocommerce._request2(type, endpoint);
    let s = r.url + start;
    if (this.config.yourSiteUrlString.indexOf('https://') != -1) {
      s = s + "consumer_key=" + this.config.consumerKey;
      s = s + "&consumer_secret=" + this.config.consumerSecret;
    }
    else if (this.config.yourSiteUrlString.indexOf('http://') != -1) {
      s = s + "oauth_consumer_key=" + r.qs.oauth_consumer_key;
      s = s + "&oauth_nonce=" + r.qs.oauth_nonce;
      s = s + "&oauth_signature=" + r.qs.oauth_signature;
      s = s + "&oauth_signature_method=" + r.qs.oauth_signature_method;
      s = s + "&oauth_timestamp=" + r.qs.oauth_timestamp;
      s = s + "&oauth_version=" + r.qs.oauth_version;
    }

    //s = s + "&" + productsArguments;
    return { url: s, headers: r.headers };
  }
  //===================================================== Get woo Request ===================================
  getHttp(req, loader = false, showAlert = false) {

    let request = this.generateWooUrl("get", req)
    let customHeaders = request.headers
    const httpOptions = {
      headers: new HttpHeaders(request.headers)
    };

    return new Promise(resolve => {
      if (loader) this.loading.show()
      if (this.platform.is('cordova')) {
        this.httpNative.get(request.url, {}, customHeaders)
          .then((data: any) => {
            let d = JSON.parse(data.data);
            if (d.message) {
              this.onReceiveError200("Get", req, {}, data.message)
              if (showAlert) this.appToastService.toastErrorWithCloseButton(data.message)
            }
            else {
              this.onReceiveSuccess("Get", req, {}, d)
              resolve(d);
            }

            // let response = this.createResponseAndRegisterError(JSON.parse(data.data), "ok", "GET", req, {})
            // resolve(response);
            if (loader) this.loading.hide()
          })
          .catch(error => {
            if (loader) this.loading.hide()
            let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "GET", req, {})
            resolve(response);
          });
      }
      else {
        this.angularHttp.get(request.url, httpOptions).subscribe((data: any) => {
          if (data.message) {
            this.onReceiveError200("Get", req, {}, data.message)
          }
          else {
            this.onReceiveSuccess("Get", req, {}, data.data)
            resolve(data);
          }
          if (loader) this.loading.hide()
        }, (error) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "GET", req, {})
          resolve(response);
        });
      }
    });
  }
  //================================================ Post Request ===============================================
  postHttp(req, postData, loader = false) {
    let customHeaders = this.getHeadersForHttp(req)
    const httpOptions = {
      headers: new HttpHeaders(customHeaders)
    };

    return new Promise(resolve => {
      if (loader) this.loading.show()
      if (this.platform.is('cordova')) {
        this.httpNative.setDataSerializer("json");
        this.httpNative.post(this.config.urlString + req, postData, customHeaders)
          .then(data => {
            if (loader) this.loading.hide()
            let d = JSON.parse(data.data);
            if (this.compareStrings(d.status, "Error")) {
              this.onReceiveError200("POST", req, postData, d.message)
            }
            else if (this.compareStrings(d.status, "Success")) {
              this.onReceiveSuccess("POST", req, postData, d.data)
              resolve(d.data);
            }
          })
          .catch(error => {
            let er = JSON.parse(error.error);
            if (loader) this.loading.hide()
            let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "POST", req, postData)
            resolve(response);
          });
      }
      else {
        this.angularHttp.post(this.config.urlString + req, postData, httpOptions).subscribe((data: any) => {
          if (loader) this.loading.hide()
          if (this.compareStrings(data.status, "Error")) {
            this.onReceiveError200("POST", req, postData, data.message)
          }
          else if (this.compareStrings(data.status, "Success")) {
            this.onReceiveSuccess("POST", req, postData, data.data)
            resolve(data.data);
          }
        }, (error) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "POST", req, postData)
          resolve(response);
        });
      }
    });
  }
  //================================================ Post Request ===============================================
  putHttp(req, postData, loader = false, showAlert = false) {
    let request = this.generateWooUrl("get", req)
    let customHeaders = this.getHeadersForHttp(req)
    const httpOptions = {
      headers: new HttpHeaders(customHeaders)
    };

    return new Promise(resolve => {
      if (loader) this.loading.show()
      if (this.platform.is('cordova')) {
        this.httpNative.setDataSerializer("json");
        this.httpNative.put(request.url + req, postData, customHeaders)
          .then((data: any) => {
            let d = JSON.parse(data.data);
            if (d.message) {
              this.onReceiveError200("Put", req, {}, data.message)
              if (showAlert) this.appToastService.toastErrorWithCloseButton(data.message)
            }
            else {
              this.onReceiveSuccess("Put", req, {}, d)
              resolve(d);
            }
            if (loader) this.loading.hide()
          })
          .catch(error => {
            if (loader) this.loading.hide()
            let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "Put", req, postData)
            resolve(response);
          });
      }
      else {
        this.angularHttp.put(request.url, postData, httpOptions).subscribe((data: any) => {
          if (data.message) {
            this.onReceiveError200("Put", req, {}, data.message)
          }
          else {
            this.onReceiveSuccess("Put", req, {}, data.data)
            resolve(data);
          }
          if (loader) this.loading.hide()
        }, (error) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "Put", req, postData)
          resolve(response);
        });
      }
    });
  }
  //================================================ delete Request ===============================================
  deleteHttp(req, postData, loader = false) {
    let customHeaders = this.getHeadersForHttp(req)
    const httpOptions = {
      headers: new HttpHeaders(customHeaders)
    };

    return new Promise(resolve => {
      if (loader) this.loading.show()
      if (this.platform.is('cordova')) {

        let postDataa: { [k: string]: any } = {};

        for (let key in postData) {
          let value = postData[key];
          postDataa[key] = String(value)
        }

        this.httpNative.setDataSerializer("json");
        this.httpNative.delete(this.config.urlString + req, postDataa, customHeaders)
          .then(data => {
            if (loader) this.loading.hide()
            let d = JSON.parse(data.data);
            if (this.compareStrings(d.status, "Error")) {
              this.onReceiveError200("DELETE", req, postData, d.message)
            }
            else if (this.compareStrings(d.status, "Success")) {
              this.onReceiveSuccess("DELETE", req, postData, d.data)
              resolve(d.data);
            }
          })
          .catch(error => {
            if (loader) this.loading.hide()
            let response = this.createResponseAndRegisterError(JSON.parse(error.error), "error", "DELETE", req, postData)
            resolve(response);
          });
      }
      else {
        let options = { headers: customHeaders, params: postData }
        this.angularHttp.delete(this.config.urlString + req, options).subscribe((data: any) => {
          if (loader) this.loading.hide()
          if (this.compareStrings(data.status, "Error")) {
            this.onReceiveError200("DELETE", req, postData, data.message)
          }
          else if (this.compareStrings(data.status, "Success")) {
            this.onReceiveSuccess("DELETE", req, postData, data.data)
            resolve(data.data);
          }
        }, (error) => {
          if (loader) this.loading.hide()
          let response = this.createResponseAndRegisterError(error, "error", "DELETE", req, postData)
          resolve(response);
        });
      }
    });
  }
  //=====================================================================
  angularHttpGet(url) {
    return new Promise(resolve => {
      this.angularHttp.get(url).subscribe((data: any) => {
        let response = this.createResponseAndRegisterError(data, "ok", "GET", url, {})
        resolve(response);
      }, (error) => {
        let response = this.createResponseAndRegisterError(error, "error", "GET", url, {})
        resolve(response);
      });
    });
  }
  //=====================================================================
  createResponseAndRegisterError(responseData, isError, methodType, requestUrl, dataPosted = {}) {

    let returnData: { [k: string]: any } = {};
    returnData.result = responseData;

    if (isError == "error") {
      this.onReceiveErrorNot200(methodType, requestUrl, dataPosted, responseData)
      returnData.status = this.errorTextString
    }
    else {
      this.onReceiveSuccess(methodType, requestUrl, dataPosted, responseData)
      returnData.status = this.successTextString
    }

    return returnData
  }
}



