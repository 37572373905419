import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { LoadingService } from 'src/services/loading/loading.service';
import { ConfigService } from 'src/services/config/config.service';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import { AppLanguageService } from 'src/services/app-language/app-language.service';


@Component({
  selector: 'app-language',
  templateUrl: './language.page.html',
  styleUrls: ['./language.page.scss'],
})
export class LanguagePage implements OnInit {
  constructor(
    public appLanguageService: AppLanguageService,
    public modalCont: ModalController,
    public config: ConfigService
  ) {

  }


  getSelectedColor(l) {
    if (l.code == this.config.languageCodeString) {
      return 'primary'
    }
  }
  //close modal
  dismiss() {
    this.modalCont.dismiss();
  }

  ngOnInit() {
  }

}
