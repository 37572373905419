import { Component, OnInit, ApplicationRef } from '@angular/core';
import { ConfigService } from 'src/services/config/config.service';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import { SignUpPage } from '../sign-up/sign-up.page';
import { ForgotPasswordPage } from '../forgot-password/forgot-password.page';
import { LoadingService } from 'src/services/loading/loading.service';
import { AppEventsService } from 'src/services/app-events/app-events.service';
import { PhoneLoginPage } from '../phone-login/phone-login.page';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import { AppCartService } from 'src/services/app-cart/app-cart.service';
import { AppToastService } from 'src/services/app-toast/app-toast.service';
import { AppTranslationService } from 'src/services/app-translation/app-translation.service';
import { AppAlertService } from 'src/services/app-alert/app-alert.service';
import { AppUserService } from 'src/services/app-user/app-user.service';
import { AppOrderService } from 'src/services/app-order/app-order.service';
import { AppLogService } from 'src/services/app-log/app-log.service';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Plugins } from '@capacitor/core'


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  formData = { email: '', password: '' };
  signUpformData = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    username: '',
    confirm_password: '',
    wpgdprc: 1,
    register: 'Register'
  };
  errorMessage = '';
  hideGuestLogin: true;
  topSegmentsString = "signIn"
  constructor(

    public config: ConfigService,
    public modalCtrl: ModalController,
    public loading: LoadingService,
    public appCartService: AppCartService,
    public shared: SharedDataService,
    public navCtrl: NavController,
    public appEventsService: AppEventsService,
    public navParams: NavParams,
    public appHttpService: AppHttpService,
    public appToastService: AppToastService,
    public appAlertService: AppAlertService,
    public appUserService: AppUserService,
    private fb: Facebook,
    private googlePlus: GooglePlus
  ) {
    this.hideGuestLogin = navParams.get('hideGuestLogin');
    this.shared.currentOpenedModel = this;
  }

  async openForgetPasswordPage() {
    let modal = await this.modalCtrl.create({
      component: ForgotPasswordPage,
    });
    return await modal.present();
  }

  login() {
    this.errorMessage = '';
    let data = { username: this.formData.email, password: this.formData.password }
    let url = this.config.yourSiteUrlString + '/wp-json/api/tc_user/generate_cookie/?insecure=cool'
    this.appHttpService.postSimpleHttp(url, data, true).then((data: any) => {
      console.log(data)
      if (data.status == "success") {
        this.getUserData(data.result, "simple");
      }
      else if (data.status == "error") {
        console.log(data.result.message)
        this.errorMessage = data.result.error.message;
      }
    });
  }
  getUserData(c, type) {
    let id;
    if (type == "simple") id = c.user.id;
    if (type == "fb") id = c.id;
    //alert(c.user.id + " -- " + c.id + " --- " + id);
    this.appHttpService.getHttp("customers/" + id, true).then((data: any) => {
      this.loading.hide();
      let dat = data
      this.appUserService.login(Object.assign({ cookie: c.cookie }, dat));
      this.dismiss();
    });
  }
  signUp() {
    if (this.signUpformData.password != this.signUpformData.confirm_password)
      this.appToastService.toast("Password Mismatched!");
    else
      this.creatAccountWithNonce();

  }

  creatAccountWithNonce() {
    this.errorMessage = '';
    let formData: { [k: string]: any } = this.signUpformData
    formData.display_name = this.signUpformData.first_name + " " + this.signUpformData.last_name
    this.appHttpService.postSimpleHttp(this.config.yourSiteUrlString + '/wp-json/api/tc_user/register/?insecure=cool', formData, true).then((data: any) => {
      console.log(data)
      if (data.status == "success") {
        //this.appToastService.toastWithCloseButton("User Created. Please Login Using your Username & Password");
        this.formData.email = this.signUpformData.username
        this.formData.password = this.signUpformData.password
        this.login()
        this.topSegmentsString = "signIn"
      }
      else if (data.status == "error") {
        console.log(data.result.message)
        this.errorMessage = data.result.error.message;
      }
    });

  }

  facebookLogin() {
    this.fb.getLoginStatus().then((res: any) => {
      if (res.status == 'connected') {
        console.log("user connected already" + res.authResponse.accessToken);
        this.createAccount(res.authResponse.accessToken, 'fb');

      }
      else {
        console.log("USer Not login ");
        this.fb.login(['public_profile', 'user_friends', 'email'])
          .then((res: FacebookLoginResponse) => {
            // this.alert.show('Logged into Facebook!' + JSON.stringify(res));
            console.log("successfully login ");
            this.createAccount(res.authResponse.accessToken, 'fb');
          })
          .catch(e => this.appAlertService.showAlert('Error logging into Facebook' + JSON.stringify(e)));
      }
    }).catch(e => this.appAlertService.showAlert('Error Check Login Status Facebook' + JSON.stringify(e)));
  }

  async openAppleSignIn() {
    const { SignInWithApple } = Plugins;

    SignInWithApple.Authorize()
      .then(async (res) => {
        if (res.response && res.response.identityToken) {
          this.createAccount(res.response, 'apple');
          console.log(res.response)
          this.appAlertService.showAlert(JSON.stringify(res.response))
        } else {
          this.presentAlert();
        }
      })
      .catch((response) => {
        this.presentAlert();
      });
    // let info = await Device.getInfo();
    // let options: SignInWithAppleOptions = {
    //   clientId: info.appId,
    //   redirectURI: this.config.urlString,
    //   // scopes: 'email name',
    //   //  state: '12345',
    //   //  nonce: 'nonce',
    // };
    // SignInWithApple.authorize(options)
    //   .then((result: SignInWithAppleResponse) => {
    //     // Handle user information
    //     // Validate token with server and create new session
    //     this.createAccount(result, 'apple');

    //   })
    //   .catch(error => {
    //     // Handle error
    //   });

    // {
    //   "response": {
    //     "email": "foo@bar.com",
    //     "identityToken": "importantToken",
    //     "familyName": "Grimm",
    //     "user": "AppleUserId",
    //     "givenName": "Simon",
    //     "authorizationCode": "authCode"
    //   }
    // }


  }

  presentAlert() {
    this.appAlertService.showAlertWithTitle("Please try again later", "Login Failed")
  }

  googleLogin() {
    this.loading.autoHide(500);
    this.googlePlus.login({})
      .then(res => {
        //  alert(JSON.stringify(res))
        this.createAccount(res, 'google');
      })
      .catch(err => this.appAlertService.showAlert(JSON.stringify(err)));
  }

  //============================================================================================  
  //creating new account using function facebook or google details 
  createAccount(info, type) {
    // alert(info);
    var dat: { [k: string]: any } = {};
    var url = '';
    if (type == 'fb') {
      url = this.config.yourSiteUrlString + '/wp-json/api/tc_user/fb_connect';
      dat.access_token = info;
    }
    else if (type == 'phone') {
      url = 'phoneregistration';
      dat.phone = info;
    }
    else {
      url = 'googleregistration';
      dat = info;
    }
    this.errorMessage = '';
    this.appHttpService.postSimpleHttp(url, dat, true).then((data: any) => {
      console.log(data)
      if (data.status == "success") {
        this.getUserData(data.result, "fb");
      }
      else if (data.status == "error") {
        console.log(data.result.message)
        this.errorMessage = data.result.error.message;
      }
    });
  };

  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    if (this.config.appInProductionBool == false) {
      this.formData.email = "jhon"
      this.formData.password = "123456"
    }
  }
}
