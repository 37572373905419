import { Injectable } from '@angular/core';
import { AppCartService } from 'src/services/app-cart/app-cart.service';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import { ConfigService } from 'src/services/config/config.service';
import { LoadingService } from 'src/services/loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class AppLanguageService {

  public languagesArray = [
    { "name": "English", "code": "en", "image": "assets/flags/english.jpg", "direction": "ltr" },
    { "name": "Arabic", "code": "ar", "image": "assets/flags/uae.jpg", "direction": "rtl" }
  ]

  language: any;

  currentLanguageCode = localStorage.languageCodeString;

  constructor(
    public loading: LoadingService,
    public config: ConfigService,
    public appCartService: AppCartService,
    public appHttpService: AppHttpService) {
    this.getLanguages()
  }

  getLanguages() {
    this.languagesArray.forEach(val => {
      if (val.code == this.currentLanguageCode)
        this.language = val;
    });
  }

  updateLanguage(v) {
    console.log(v.code);
    if (this.currentLanguageCode != v.code) {
      console.log(v.code);
      this.loading.autoHide(1000);
      
      localStorage.languageCodeString = v.code;
      localStorage.appDirectionString = v.direction;

      this.appCartService.emptyCart();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

}
